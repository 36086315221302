
export function init() {
    if(_config.iframe && _config.iframeResize ) {
        if( insideIFrame() ) {
            setupMessageBus();
        }
    }
}

function getHeight() {
    var body = document.body,
        html = document.documentElement;

    var height = Math.max(
        body.offsetHeight,
        html.offsetHeight
    );

    if (height === 0) {
        // this is needed for IE because the other values are not supported
        // the body.scrollHeight must not be included on other devices (ie iOs) because it is equal to the set
        // height of the iframe and does not represent the actual height of the content
        height = body.scrollHeight;
    }

    return height;
}

function insideIFrame() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function setupMessageBus() {
    let lastHeight = null;

    window.addEventListener('message', function (msg) {
        if (msg.data === "sizing?") {
            lastHeight = getHeight();
            parent.postMessage({'size': lastHeight},'*');
        }
    });

    lastHeight = getHeight();
    parent.postMessage({'size': lastHeight},'*');
    setInterval(function () {
        let newHeight = getHeight();
        if (Math.abs(newHeight - lastHeight) > 5) {
            parent.postMessage({'size': newHeight},'*');
            lastHeight = newHeight
        }
    }, 1000);

}