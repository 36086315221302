import {isParsleyForm, isValid} from "../../@elements/parsley-bootstrap-validation";
import {getPrefixedDataSet} from "../../@elements/data-set-utils";
import $ from "jquery";
import 'url-polyfill';
import {getElementBySelector, getElementsBySelectorObject} from "../../@elements/ajax-form/selector-util.js";
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from '../../@elements/fetch'; // IE10 Polyfill
import {debounce} from "debounce";
import asyncAppend from '../../@elements/async-append';
import formDataEntries from 'form-data-entries';
import initModulesInScope from "../../@elements/init-modules-in-scope";

const defaultSelectors = {
    base: '.js-ajax-form',
    result: '.js-ajax-form__result',
    loading: '.js-ajax-form__loading',
    notifications: '.js-ajax-form__notifications',
    form: '.js-ajax-form__form',
    additionalForm: '.js-ajax-form__additional-form',
    errorArea: '.js-ajax-form__error-area',
    retry: '.js-ajax-form__retry',
    link: '.js-ajax-form__link',
    reset: '.js-ajax-form__reset',
    reload: '.js-ajax-form__reload'
};

const defaultOptions = {
    submitOnChange: false,
    addUrlParams: false,
    fetchHeaders: {},
    resultMappings: []
};

export function createInitInScope(options = defaultOptions, selectors = defaultSelectors) {
    return function ($scope) {
        return getElementBySelector(selectors.base, $scope).each(function () {
            createAjaxForm($(this), {...defaultSelectors, ...selectors}, options);
        });
    }
}

export function createAjaxForm($baseElement, selectors = defaultSelectors, options = defaultOptions) {
    let $elements = getElementsBySelectorObject({...defaultSelectors, ...selectors}, $baseElement);

    let pendingRequest = null;

    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('ajax-form', $baseElement)
    };

    let $disableAdditionalForms = false;
    if( $elements.form.length > 1 ) {
        // multiple forms
        // only enable additional forms if there is ONE "primary-form", as there is no connection between "forms" and additional
        $disableAdditionalForms = true;
        addMultiFormSubmitHandler();
    } else {
        // regular workflow - ONE primary form
        addSubmitHandler();
    }

    function replaceMultiFormSubmitHandler( $newForms, $triggerForm ) {

        // remove previous handlers - note: dom no longer available ?
        $elements.form.each((index, form) => {
            $(form).off('submit');
            $(form).off('change');
        });
        // replace previous
        $elements.form = $newForms;
        addMultiFormSubmitHandler( $newForms );

    }

    function deriveSubmitter( evt, $form ) {
        // https://developer.mozilla.org/en-US/docs/Web/API/SubmitEvent
        let submitter = null;
        if( evt.submitter ) {
            submitter = evt.submitter;
        } else
        if( evt.originalEvent && evt.originalEvent.submitter ) {
            submitter = evt.originalEvent.submitter;
        } else
        if( document.activeElement && $(document.activeElement).is('button') ) {
            // https://gist.github.com/nuxodin/3ae174f2a6a112df3ccad22459237a91
            submitter = document.activeElement;
        }
        if( submitter ) {
            if( $form.has( submitter ) ) {
                // button in form
                return submitter;
            } else {
                // button outside form but connected with id
                let targetFormId = $(submitter).attr( 'form');
                if( targetFormId && $form.is( '#' + targetFormId ) ) {
                    return submitter;
                }
            }
        }
        return null;

    }

    function addMultiFormSubmitHandler($forms = $elements.form) {
        $forms.each((index, form) => {
            $(form).on('submit', function (evt) {
                evt.preventDefault();
                evt.stopImmediatePropagation(); // otherwise .on('submit.ajax-form') would be called twice
                let submitter = deriveSubmitter( evt, $(this));
                if( submitter ) {
                    submitFromTrigger( $(submitter), $(this) );
                } else {
                    submitMultiForm( $(this) );
                }

            });
            // submit-on-change option not possible for multi-forms
            if( $(form).data('ajax-form-submit-on-change')) {
                $(form).on('change', debounce(function () {
                    submitMultiForm($(this));
                }, 200));

            }
        });
    }
    function addSubmitHandler($form = $elements.form) {
        $form.on('submit', function (evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation(); // otherwise .on('submit.ajax-form') would be called twice
            let submitter = deriveSubmitter( evt, $(this) );
            if( submitter ) {
                submitFromTrigger( $(submitter), $(this) );
            } else {
                submitForm($(this) );
            }
        });

        if( options.submitOnChange ) {
            $form.on('change', debounce(function () {
                submitForm($(this));
            }, 200));
        }
    }

    if( !$disableAdditionalForms  ) {
        $elements.additionalForm.on('submit', function (evt) {
            evt.preventDefault();
            submitForm($(this));
        });
        $elements.additionalForm.each((index, form) => {
            if (options.submitOnChange || $(form).data('ajax-form-submit-on-change')) {
                $(form).on('change', debounce(function () {
                    submitForm($(this));
                }, 200));
            }
        });
    }

    $elements.retry.on('click', function (evt) {
        evt.preventDefault();

        if (lastLoadParams) {
            load(...lastLoadParams);
        }
    });

    // Links
    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));

    function addLinkClickHandler($links) {
        $links.on('click', function (evt) {
            evt.preventDefault();

            let href = $(this).data('href') || $(this).attr('href');
            let action = $elements.form.data('action') || $elements.form.attr('action');
            let params = new URL(href, location.origin).searchParams;

            pendingRequest = load(action, 'GET', params, href);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if ((!error || error.name !== 'AbortError') // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });
        });
    }

    let externalReloadRegistered = false;
    let $externalReloadReference = null;

    addReloadHandler( getElementBySelector(selectors.reload, $baseElement));
    function addReloadHandler($elements) {
        $elements.on('click', function (evt) {
            evt.preventDefault();
            reloadFrom( $(this) );
        });

        // external trigger - make sure it is only registered once!
        // or a change in ajax-form will lead to multiple listeners -> triggering reload N times...
        if( !externalReloadRegistered ) {
            externalReloadRegistered = true;
            $baseElement.on('do-reload.ajax-form', function() {
                if( $externalReloadReference ) {
                    reloadFrom( $externalReloadReference );
                }
            } );
        }
        // permit only if unique
        if( $elements.length === 1 ) {
            // note: change only if different ?
            // set or update reference element - may have changed in between reloads
            $externalReloadReference = $elements.first()
        }
    }


    function reloadFrom( $element ) {
        let href = $element.attr('href') || $element.data('href');
        if( href ) {
            let method = $element.data('method') || 'GET'
            let params = new URL(href, location.origin).searchParams;
            pendingRequest = load(href, method, params);
            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if ((!error || error.name !== 'AbortError') // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });

        }

    }

    // Reset buttons
    addResetHandler(getElementBySelector(selectors.reset, $baseElement));

    function addResetHandler($resetButtons) {
        $resetButtons.on('click', function (evt) {
            let $inputs = $elements.form.find(':input');
            let resetName = $(evt.target).data('reset-name');
            let resetValue = $(evt.target).data('reset-value');
            evt.preventDefault();

            if (resetName) {
                let selectorName = resetName
                    .split(',')
                    .map(name => `[name="${name.trim()}"]`)
                    .join(', ');

                $inputs = $inputs.filter(selectorName);
            }

            if (resetValue) {
                let selectorValue = resetValue.toString()
                    .split(',')
                    .map(value => `[value="${value.trim()}"]`)
                    .join(', ');

                $inputs = $inputs.filter(selectorValue);
            }

            $inputs.filter(':not(:input[type="checkbox"], :input[type="radio"])').val('');
            $inputs.filter(':input[type="radio"], :input[type="checkbox"]').prop('checked', null);

            $inputs.trigger('change');
            $baseElement.trigger('reset.ajax-form');
        });
    }

    let lastLoadParams = null; // for retry
    function load(url, method = "GET", params, historyUrl, $form ) {
        lastLoadParams = [url, method, params, historyUrl];

        if (options.addUrlParams) {
            history.replaceState(history.state, document.title, historyUrl || url);
        }

        // add base url to params (path)
        // params.append('baseUrl', location.pathname);

        if (method.toUpperCase() === "GET") {
            params.append('ajax', 1);
            // Add ajax param to differentiate between and ajax requests and page request.
            // Otherwise Chrome caches these results as normal pages and returns them from cache if the back button is pressed
            url = addSearchParamsToUrl(url, params);
        }

        let request = fetch(url, {
            method: method,
            headers: {
                'pragma': 'no-cache',
                'cache-control': 'no-cache',
                ...options.fetchHeaders
            },
            ...(method.toUpperCase() !== "GET" ? {
                body: new URLSearchParams(params)
            } : {})
        });

        $baseElement.trigger('fetch.ajax-form', request);


        let $targetsByResultId = {};
        $elements.result.toArray().forEach((element) => {
            let resultId = $(element).data('result-id') || 'default';
            if ($targetsByResultId[resultId]) {
                $targetsByResultId[resultId] = $targetsByResultId[resultId].add($(element));
            } else {
                $targetsByResultId[resultId] = $(element);
            }
        });

        let backupContentResults = [];

        if( options.resultMappings != null && typeof options.resultMappings === 'object' ) {
            // e.g. [ { id: 'item-1', selector: 'item_1_container' } ]
            let externalResults = !Array.isArray( options.resultMappings ) ? [ options.resultMappings ] : options.resultMappings;
            for( const nextResult of externalResults ) {
                const nextResultId = nextResult.hasOwnProperty( 'id') ? nextResult.id : '';
                const nextResultSelector = nextResult.hasOwnProperty( 'selector') ? nextResult.selector : '';
                if(
                    typeof nextResultId === 'string' && nextResultId.length &&
                    typeof nextResultSelector === 'string' && nextResultSelector.length
                ) {
                    let $nextElement = nextResultSelector.startsWith( '#') ? $( nextResultSelector ) : $( '#' + nextResultSelector );
                    if( $nextElement.length === 1 ) {

                        // problem with async-append in case content for this result is missing
                        // all targets are emptied before their content is updated...
                        if( $nextElement.data( 'result-prevent-empty') ) {
                            // workaround: store current and restore to previous if empty
                            backupContentResults.push({
                                id: nextResultId, $element: $nextElement,
                                content: $nextElement.html()
                            });
                        }
                        // only permit unique results for now
                        $targetsByResultId[nextResultId] = $nextElement;
                        /*
                        if ($targetsByResultId[nextResultId]) {
                            $targetsByResultId[nextResultId] = $targetsByResultId[nextResultId].add($nextElement);
                        } else {
                            $targetsByResultId[nextResultId] = $nextElement
                        }*/
                    }
                }
            }
        }

        asyncAppend(
            {
                $target: $targetsByResultId,
                $loading: $elements.loading,
                $notifications: $elements.notifications
            },
            request
        )
            .then((result) => {
                let content = result.html || result.content;
                if (content && result.success !== false) {
                    $baseElement.trigger('success.ajax-form');
                    $elements.errorArea.attr('hidden', 'hidden');
                    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));
                    addResetHandler(getElementBySelector(selectors.reset, $baseElement));
                    addReloadHandler(getElementBySelector(selectors.reload, $baseElement));

                    let $newForm = getElementBySelector(selectors.form, $baseElement);
                    if($newForm && $newForm.length ) {
                        if( $newForm.length > 1 || ($elements.form.length > 1 || $form )) {
                            // multi-form context
                            replaceMultiFormSubmitHandler( $newForm, $form );
                        } else
                        if( $newForm[0] !== $elements.form[0] ) {
                            $elements.form = $newForm;
                            addSubmitHandler($elements.form);
                        }
                    }
                } else {
                    $baseElement.trigger('failed.ajax-form');
                    $elements.errorArea.attr('hidden', null);
                }
                // async append workaround restore
                if( result.success !== false && backupContentResults.length ) {
                    backupContentResults.forEach( function( backup ) {
                        if( !backup.$element.html().trim().length ) {
                            // restore prev content and re-init
                            backup.$element.append( backup.content );
                            initModulesInScope( backup.$element );
                        }
                    } );
                }

                $baseElement.trigger('fetched.ajax-form', result);
            })
            .catch(() => {});

        // Unpack json response body if the promise was created via fetch
        // Otherwise the HTTP-Server error is not caught.
        // The fetch promise itself resolves (even with a http error)
        request.then(response => (response
            && response.json
            && typeof response.json === 'function'
            && response.clone
            && typeof response.clone === 'function')
            ? response.clone().json()
            : response
        ).catch((error, requestState) => {
            if ((!error || error.name !== 'AbortError') // native fetch abort
                && requestState !== 'abort') { // jquery abort
                console.error(error);
                $baseElement.trigger('failed.ajax-form');
                $elements.errorArea.attr('hidden', null);
            }
        });

        return request;
    }

    function submitMultiForm( $form, action, method, novalidate ) {
        return submit(
            action || $form.data('action') || $form.attr('action'),
            method || $form.data('method') || $form.attr('method'),
            $form,
            novalidate
        )
    }
    function submitForm( $form, action, method, novalidate ) {
        return submit(
            action || $form.data('action') || $form.attr('action'),
            method || $form.data('method') || $form.attr('method'),
            novalidate
        )
    }

    function submitFromTrigger( $submitter, $form, novalidate ) {
        return submit(
            $submitter.attr( 'formaction') || $submitter.data('formaction'),
            $submitter.attr( 'formmethod') || $submitter.data('formmethod'),
            $form,
            novalidate || $submitter.attr( 'formnovalidate') || $submitter.data('formnovalidate'),
        )

    }

    function submit( action, method, $form, novalidate ) {
        // create promise to resolve/reject in right order (important for loading-indicator with multiple submissions)
        let readyToSubmit = new Promise(function (resolve, reject) {
            if (pendingRequest && pendingRequest.abort) {
                pendingRequest.abort();
                pendingRequest.catch(resolve);
                pendingRequest = null;
            } else {
                resolve();
            }
        });

        readyToSubmit.then(function () {
            let $referenceForm = $form ? $form : $elements.form;
            if( !novalidate && isParsleyForm($referenceForm) && !isValid($referenceForm)) {
                return;
            }

            $baseElement.trigger('submit.ajax-form');

            action = action || $referenceForm.data('action') || $referenceForm.attr('action');
            method = method || $referenceForm.data('method') || $referenceForm.attr('method');
            let formDataEntries = getFormDataEntries( $form );
            let params = new URLSearchParams(formDataEntries);

            call(options.onSubmit, {
                $element: $baseElement,
                $elements,
                options,
                formData: getFormData( $form )
            });

            let url = new URL(location.href);
            url.searchParams.delete('page');
            url = addSearchParamsToUrl(url, params);

            pendingRequest = load(action, method, params, url, $form );

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if ((!error || error.name !== 'AbortError') // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });
        });
    }

    function getFormData( $form ) {
        if( $form ) {
            return createFormData([$form[0]]);
        } else
        if( $disableAdditionalForms ) {
            return createFormData([$elements.form[0]]);
        } else {
            return createFormData([$elements.form[0], ...$elements.additionalForm.toArray()]);
        }
    }

    function getFormDataEntries( $form ) {
        if( $form ) {
            return createFormData([$form[0]]);
        } else
        if( $disableAdditionalForms ) {
            return createFormDataEntries([$elements.form[0]]);
        } else {
            return createFormDataEntries([$elements.form[0], ...$elements.additionalForm.toArray()]);
        }

    }

    let api = {
        submit,
        submitFromTrigger,
        getFormData,
        getFormDataEntries // todo add to doku
    };

    $baseElement.data('ajax-form', api);

    return api;
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}

export const initInScope = createInitInScope();

function createFormData(forms) {
    let formData = new FormData();
    forms.map(form => {
        for (var pair of formDataEntries(form)) {
            formData.append(...pair);
        }
    });

    return formData;
}

function createFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        // unchecked checkboxes and radios needs to be added manually to formDataArray
        let $selectors = $(form).find(':input[type="radio"], :input[type="checkbox"]');
        let selectorNames = [];

        $selectors.map(function() {
            selectorNames.push(this.name);
        });

        for (var pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }

        let existingNames = formDataArray.map(entry => entry[0]);
        selectorNames = [...new Set(selectorNames)];

        selectorNames.forEach(function (name) {
            let newEntry = [name, ""];
            if(!existingNames.includes(name)) {
                formDataArray.push(newEntry);
            } else {
                formDataArray.filter(item => item !== newEntry);
            }
        });
    });

    return formDataArray;
}


function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}
