import $ from "jquery";
import loadJQueryUi from '../libs/@elements/load-jquery-ui';
import {getDate, setMinDate, setDate, setMaxDate} from "../libs/@elements/datepicker";
import {getPrefixedDataSet} from "../libs/@elements/data-set-utils";
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "../libs/@elements/date-utils";

const dayInMilliseconds = 24 * 60 * 60 * 1000;

const defaultSelectors = {
    base: '.js-datepicker-range',
    datepickerFrom: '.js-datepicker-range__from',
    datepickerTo: '.js-datepicker-range__to',
    datepicker: '.js-datepicker__input'
};

const rangeFormatLocal = true;

const defaultOptions = {
    defaultDuration: 1, // days
    maxDuration: null, // days
    constraintRange: { // min, max dates
        start: null, end: null
    },
    ranges: [], // multiple ranges (min,max)
    closedDates: [] // single dates
}


function isWithinRange( date, range ) {
    return date >= range.start && date <= range.end;
}


function dayInRange( date, ranges, emptyDefault = true ) {
    if( ranges.length ) {
        return ranges.findIndex( function( range ) {
            return isWithinRange( date, range );
        }) >= 0;
    } else {
        return emptyDefault;
    }
}

function dayContained( date, isoDays ) {
    if( isoDays.length > 0 ) {
        const day = dateToISOString( date, false );
        return isoDays.includes( day );
    } else {
        return false;
    }
}

function dayAccepted( options ) {
    return function( date ) {
        // restrict to ranges if more then one is given, case for 1 range should be reflected with proper min / max
        if( options.ranges.length > 1 && !dayInRange( date, options.ranges ) ) {
            // out of seasonal bounds
            return [false, 'text-danger', 'out-of-bounds'];
        }
        if( options.closedDates.length > 0 && dayContained( date, options.closedDates ) ) {
            // closed
            return [false, 'text-warning', 'closed'];
        }
        return [ true, '' ];
    };
}

function parseDateRange( range ) {
    if( range === null || typeof range !== 'object') {
        return null;
    }
    if( !range.hasOwnProperty( 'start') || !range.hasOwnProperty( 'end') ) {
        return null;
    }
    // check for non blank string
    if( typeof range.start !== 'string' || !range.start.length ) {
        return null;
    }
    if( typeof range.end !== 'string' || !range.end.length ) {
        return null;
    }
    let start = ISOStringToDate( range.start );
    let end = ISOStringToDate( range.end );
    // legacy - start + end may be off by one day
    return {
        start: UTCDateToLocalDate( start ), end: UTCDateToLocalDate( end ),
        startUTC: localDateToUTCDate( start ), endUTC: localDateToUTCDate( end )
    };


}

function addDays( date, days = 1 ) {
    var mutated = new Date(date);
    mutated.setDate(mutated.getDate() + days);
    return mutated;
}

function _addDays( date, days = 1 ) {
    // todo: what's the correct way to add days ?
    // note: passed in date is actually a timestamp...
    return roundDate( new Date(date).getTime() + days * dayInMilliseconds );
}

function roundDate(date) {
    if (date) {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }
}


function updateMinMaxForTo( options, fromDate, $to ) {
    if( fromDate != null ) {
        // setup proper min / max
        let toMin = fromDate;

        if( options.maxDuration != null && options.maxDuration > 0 ) {
            let toMax = addDays( toMin, options.maxDuration - 1 );
            // season-ranges, will prevent end-date selection over boundaries
            // should only be relevant if more then one range is given, otherwise default constraint should be applied
            if( options.ranges.length > 1 ) {
                // find matching ranges
                let matching = {
                    from: null,
                    to: null
                }
                for( const range of options.ranges ) {
                    if( matching.from === null && isWithinRange( toMin, range ) ) {
                        matching.from = range;
                    }
                    if( matching.to === null && isWithinRange( toMax, range ) ) {
                        matching.to = range;
                    }
                    if( matching.to && matching.from ) {
                        break;
                    }
                }

                if( matching.to && matching.from && matching.to !== matching.from ) {
                    // from-max within other range / season
                    // use max from to-range
                    toMax = matching.from.endUTC;
                }
            }
            // default fallback to constraint, case for <= 1 ranges
            if( options.constraintRange.end && toMax > options.constraintRange.end ) {
                toMax = options.constraintRange.endUTC;
            }

            // note: needs further investigation, seems of by one ...
            // but it seems closed-days is somehow already handled prevent wrong "max-date"
            const disableClosedCheck = true;
            if( !disableClosedCheck ) {
                // off by one due too different formats ?
                //let maxDate = localDateToUTCDate( toMax );
                let maxDate = toMax;
                if( dayContained( maxDate, options.closedDates ) ) {
                    // note: max-date of ranges / constraint may be on a closed-date
                    // find next possible open-date, but don't go below min-date
                    let nextMax = addDays( maxDate, -1 );
                    while( dayContained( nextMax, options.closedDates ) && nextMax >= toMin ) {
                        nextMax = addDays( nextMax, -1 );
                    }
                    if( nextMax <= toMin ) {
                        // no opened date available, use min
                        toMax = toMin;
                    } else {
                        toMax = nextMax;
                    }
                }
            }
            let minOffset = toMin.getTimezoneOffset();
            let maxOffset = toMax.getTimezoneOffset();
            if( minOffset > maxOffset ) {
                let dstOffset = minOffset - maxOffset;
                // DST transition: +1 hour (60 minutes) , winter -> summer
                setMinDate( $to, toMin );
                // add 1 hour due too internal conversion
                let toMaxShifted = new Date( toMax.getTime() + (dstOffset * 60 * 1000) );
                setMaxDate( $to, toMaxShifted );
            } else
            if( maxOffset > minOffset ) {
                let dstOffset = maxOffset - minOffset;
                // DST transition: -1 hour (-60 minutes), summer -> winter
                // sub 1 hour due too internal conversion
                setMinDate( $to, toMin );
                let toMaxShifted = new Date( toMax.getTime() - (dstOffset * 60 * 1000) );
                // note: should not be required
                setMaxDate( $to, toMax );
            } else {
                setMinDate( $to, toMin );
                setMaxDate( $to, toMax );

            }

        }
    }
}

export function createInitInScope(selectors = defaultSelectors, options = defaultOptions) {
    return function ($scope) {

        let $ranges = $scope.find( selectors.base );
        $ranges.each( function() {
            let $rangePicker = $(this);
            let $from = $rangePicker.find( selectors.datepickerFrom );
            let $to = $rangePicker.find( selectors.datepickerTo );
            let rangeOptions = {
                ...options,
                ...getPrefixedDataSet('datepicker-range', $rangePicker )
            };

            // push nested options onto root
            if( rangeOptions.options ) {
                let nestedOptions = rangeOptions.options;
                delete rangeOptions.options;
                rangeOptions = { ...rangeOptions, ...nestedOptions };
            }

            // parse optional overall constraint - may have been provided from nested options
            let constrainted = parseDateRange( rangeOptions.constraintRange )
            if( constrainted != null ) {
                rangeOptions.constraintRange = constrainted;
            } else {
                // reset to null
                rangeOptions.constraintRange = {
                    start: null, end: null
                }
            }

            // parse ranges if given
            if( rangeOptions.ranges.length ) {
                // parse into min,max ranges and discard empty (invalid) entries
                let validatedRanges = rangeOptions.ranges.map( parseDateRange ).filter( r => r );
                // now replace raw with parsed ranges
                rangeOptions.ranges = validatedRanges;
            }
            if( rangeOptions.closedDates.length ) {
                // convert to UTC...
                let closedDays = rangeOptions.closedDates.map( function( isoDay ) {
                    return dateToISOString(UTCDateToLocalDate(ISOStringToDate( isoDay )),false);
                });
                // now replace for lookup
                rangeOptions.closedDates = closedDays;
            }

            // init with defaults restrictions
            loadJQueryUi().then(function () {
                let $fromPicker = $from.find( selectors.datepicker );
                $fromPicker.datepicker( 'option', 'beforeShowDay', dayAccepted( rangeOptions ) )

                let $toPicker = $to.find( selectors.datepicker );
                $toPicker.datepicker( 'option', 'beforeShowDay', dayAccepted( rangeOptions ) )

                // init with proper min / max
                updateMinMaxForTo( rangeOptions, getDate( $from ), $to );

            });

            $from.on('change', function () {
                // forward restriction
                updateMinMaxForTo( rangeOptions, getDate( $(this) ), $to );
            });

        } );
        return $ranges;
    }
}

export const initInScope = createInitInScope();